const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_O08Qe2mK8",
    "aws_user_pools_web_client_id": "cgigitia1q9v22hga1h0mfr68",
    "aws_user_files_s3_bucket": "senso-static-files-us",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "oauth": {
        "domain": 'ssocustomers.auth.us-east-1.amazoncognito.com',
        "scope": ['email', 'openid', 'profile'],
        "redirectSignIn": 'https://arrowhead.cucopilot.com/callback',
        "redirectSignOut": 'https://arrowhead.cucopilot.com/logout',
        "responseType": 'code'
    }
};

export default awsmobile;